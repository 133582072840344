import React from 'react';
import Layout from '../../components/common/Layout';
import RightToBeForgottenForm from '../../components/from/RightToBeForgotten/RightToBeForgottenForm';
import SEO from '../../components/SEO';
import Container from '../../components/common/Container';

const RightToBeForgottenFormPage = () => (
  <Layout lang="en">
    <Container>
      <SEO
        title="Right to be forgotten - Nextcloud Hosting"
        description="You can get your data deleted here."
      />
      <RightToBeForgottenForm />
    </Container>
  </Layout>
);

export default RightToBeForgottenFormPage;
