import styled from 'styled-components';
import checkMarkIcon from '../../../../assets/icons/checkmark.svg';

export const CheckBox = styled.label`
  cursor: pointer;
  margin-bottom: 0.3rem;
  input {
    display: none;

    + span:before {
      content: '';
      padding: 1.5px 10px;
      background: #fff;
      transition: all 0.4s ease;
      border-radius: 3px;
      background-image: url('${checkMarkIcon}');
      background-size: 12px;
      background-position: center;
      background-repeat: no-repeat;
      border: 2px solid #019ee0;
      margin-right: 0.5rem;
    }

    &:checked + span {
      &:before {
        content: '';
        padding: 1.5px 10px;
        background: #019ee0;
        transition: all 0.4s ease;
        border-radius: 3px;
        background-image: url('${checkMarkIcon}');
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 0.5rem;
      }
    }
  }
`;

export const Fieldset = styled.div`
  label {
    display: inline-block;
  }
`;

export const ErrorField = styled.span`
  color: red;
`;
