/* eslint-disable jsx-a11y/label-has-associated-control,no-undef */
import React from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../common/InputField';
import Button from '../../../common/Button';
import { Fieldset, ErrorField } from './styles';

const RightToBeForgottenForm = ({
  intl,
  intl: { formatMessage },
}) => (
  <div>
    <Formik
      enableReinitialize
      initialValues={{
        cloudname: '',
        email: '',
        language: '',
        deleteMode: '',
        feedback: '',
        success: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .strict()
          .trim(formatMessage({ id: 'invalid_space' }))
          .email(formatMessage({ id: 'invalid_email' }))
          .required(formatMessage({ id: 'required_email' })),
        cloudname: Yup.string()
          .strict()
          .trim(formatMessage({ id: 'invalid_space' }))
          .matches(
            /^[a-z0-9A-Z]+$/,
            formatMessage({ id: 'invalid_alphanum' }),
          )
          .required(formatMessage({ id: 'required_username' })),
      })}
      onSubmit={async (
        { email, cloudname, feedback, deleteMode },
        { setSubmitting, setFieldError, setFieldValue },
      ) => {
        try {
          const language = intl.locale;
          await axios.post(
            `${process.env.BACKEND_API_URL}/@delete-cloud-form`,
            {
              cloud_id: cloudname,
              email,
              language,
              feedback,
              delete_mode: deleteMode,
            },
            {
              headers: {
                accept: 'application/json',
              },
            },
          );

          setFieldValue('success', true);
          setSubmitting(false);
          navigate(
            language === 'de'
              ? '/de/right_to_be_forgotten_success'
              : '/en/right_to_be_forgotten_success',
          );
        } catch (err) {
          setFieldError('email', err.message);
          setSubmitting(false);
          alert('Something went wrong');
        }
      }}
    >
      {({ touched, errors }) => (
        <Form>
          {['cloudname', 'email'].map((item) => (
            <Fieldset key={item}>
              <label htmlFor={item}>
                <FormattedMessage id={`input_delete_${item}`} />
              </label>
              <div>
                <InputField
                  as={Field}
                  id={item}
                  component="input"
                  type={item === 'email' ? 'email' : 'text'}
                  name={item}
                  error={touched[item] && errors[item]}
                />
              </div>
              <ErrorMessage component={ErrorField} name={item} />
            </Fieldset>
          ))}
          <Fieldset>
            <label>
              <Field
                id="delete_after_expire"
                type="radio"
                name="deleteMode"
                value="delete_after_expire"
              />{' '}
              <FormattedMessage id="right_to_be_forgotten_form_delete_expire" />
            </label>
            <div />
            <label>
              <Field
                id="delete_immediately"
                type="radio"
                name="deleteMode"
                value="delete_immediately"
              />{' '}
              <FormattedMessage id="right_to_be_forgotten_form_immediate_delete" />
            </label>
          </Fieldset>
          <Fieldset>
            <div role="group" aria-labelledby="checkbox-group">
              <label>
                <Field
                  id="feedback_installed_own"
                  type="radio"
                  name="feedback"
                  value="feedback_installed_own"
                />{' '}
                <FormattedMessage id="right_to_be_forgotten_form_installed_own" />
              </label>
              <div />
              <label>
                <Field
                  id="feedback_not_used"
                  type="radio"
                  name="feedback"
                  value="feedback_not_used"
                />{' '}
                <FormattedMessage id="right_to_be_forgotten_form_not_used" />
              </label>
              <div />
              <label>
                <Field
                  id="feedback_errors"
                  type="radio"
                  name="feedback"
                  value="feedback_errors"
                />{' '}
                <FormattedMessage id="right_to_be_forgotten_form_errors" />
              </label>
              <div />
              <label>
                <Field
                  id="feedback_expensive"
                  type="radio"
                  name="feedback"
                  value="feedback_expensive"
                />{' '}
                <FormattedMessage id="right_to_be_forgotten_form_expensive" />
              </label>
            </div>
          </Fieldset>
          <Button type="submit">Register</Button>
        </Form>
      )}
    </Formik>
  </div>
);

export default injectIntl(RightToBeForgottenForm);
